import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Hidden, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchItems } from "../actions";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";

//Components
import Breadcrumb from "./Breadcrumb";
import CategoryWrapper from "./CategoryWrapper";
import Cart from "./Cart";

class BonusContent extends Component {
  componentDidUpdate() {
    if (this.props.width === "xs") {
      if (this.props.cart.items.length > 0) {
        document.getElementById("bonusContinue").style.display = "block";
      } else {
        document.getElementById("bonusContinue").style.display = "none";
      }
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={24}
          className={classes.MainContainer}
        >
          <Grid item className={classes.Center}>
            <Grid container justify="center" alignItems="center" spacing={24}>
              {/* <Grid item xs={12} sm={12} md={1} lg={1} xl={0} /> */}
              <Grid item xs={12} sm={12} md={8} lg={8} xl={12}>
                <Hidden only={["xs", "sm"]}>
                  <Breadcrumb currentPage="Bonus Items" />
                </Hidden>
                <div className={classes.container}>
                  <Grid container spacing={24}>
                    {this.props.categories.map(
                      (category, index) =>
                        category.is_bonus === true && (
                          <CategoryWrapper
                            key={index}
                            name={category.CatName}
                            category={category.id}
                            items={category.items}
                            label={category.label}
                            ref={"cat" + category.id}
                          />
                        )
                      // <Item key={item.id} item={item} />
                    )}
                  </Grid>
                  <Hidden only={["md", "lg", "xl"]}>
                    <div className={classes.ScreenAction} id="bonusContinue">
                      <Button
                        variant="extendedFab"
                        aria-label="Next"
                        className={classes.BonusButton}
                        color="secondary"
                        component={Link}
                        to="/step3"
                      >
                        Continue
                      </Button>
                    </div>
                  </Hidden>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={0}> */}
              <Cart step="/step3" />
              {/* </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  MainContainer: {
    paddingTop: "120px",
    paddingBottom: "100px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "167px"
    }
  },
  Center: {
    maxWidth: "1350px",
    // [theme.breakpoints.up("lg")]: {
    //   maxWidth: "1440px",
    //   paddingLeft: "0 !important"
    // }
    // [theme.breakpoints.down("lg")]: {
    //   paddingLeft: "40px !important"
    // },
    // [theme.breakpoints.down("lg")]: {
    //   paddingLeft: "0px !important"
    // }
  },
  container: {
    paddingLeft: "20px",
    paddingTop: "70px",
  },
  BlockTitle: {
    height: "31px",
    fontFamily: "Cabin",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4cb9e9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      width: "630px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      width: "375px"
    }
  },
  BoldText: {
    fontFamily: "Cabin",
    fontWeight: "bold"
  },
  ScreenAction: {
    width: 100 - 10 + "%",
    bottom: 40,
    position: "fixed",
    textAlign: "center"
  },
  BonusButton: {
    width: "315px",
    height: "56px",
    borderRadius: "4px",
    backgroundColor: "#4cb9e9",
    fontFamily: "Cabin",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.75",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  }
});

const mapStateToProps = state => {
  // console.log("Bonus state: ", state);
  return {
    categories: state.categories,
    cart: state.cart
  };
};

export default connect(
  mapStateToProps,
  { fetchItems }
)(
  compose(
    withStyles(styles, { withTheme: true }),
    withWidth()
  )(BonusContent)
);
