import React, { Component } from "react";
import {
  Grid,
  Card,
  CardContent,
  withStyles,
  CardMedia,
  Hidden,
  Fab
} from "@material-ui/core";
import { connect } from "react-redux";
import { addToCart, deleteFromCart, selectedCategory } from "../actions";
import _ from "lodash";

class Item extends Component {
  constructor(props) {
    super(props);
    this.handleAddToCart = this.handleAddToCart.bind(this);
  }
  handleAddToCart = e => {
    e.preventDefault();

    let itemParam = {
      id: this.props.item.id,
      category: this.props.category,
      name: this.props.item.name,
      img: this.props.item.img,
      price: this.props.item.price,
      inCart: false
    };
    const categoryId = this.props.category;
    const itemCategory = _.find(this.props.categories, function(o) {
      return o.id === categoryId;
    });

    if (this.props.item.inCart === false) {
      this.props.addToCart(itemParam);
      this.props.selectedCategory(itemCategory.id, itemCategory.CatName);
    } else {
      this.props.deleteFromCart(itemParam);
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid item className={classes.root} xs={12} sm={6} md={4} lg={4} xl={2}>
        <Hidden only={["xs"]}>
          <Card className={classes.Card}>
            <img
              src={this.props.item.img}
              alt="Water Bottles"
              style={{ width: "100%" }}
            />
            <CardContent className={classes.CardContent}>
              <div style={{ flex: 1 }}>
                <h3 className={classes.CardTitle}>{this.props.item.name}</h3>
                <p className={classes.Price}>
                  Starting at {this.props.item.price}
                </p>
              </div>
              <div style={{ paddingTop: "15px" }}>
                <Fab
                  color="primary"
                  aria-label="Add"
                  className={
                    this.props.item.inCart === false
                      ? classes.AddButton
                      : classes.AddButtonActive
                  }
                  onClick={this.handleAddToCart}
                  disableTouchRipple={true}
                >
                  {" "}
                </Fab>
              </div>
            </CardContent>
          </Card>
        </Hidden>
        <Hidden only={["sm", "md", "lg", "xl"]}>
          <Card className={classes.Card}>
            <Grid container spacing={24}>
              <Grid item xs={3}>
                <CardMedia title={this.props.name}>
                  <img
                    className={classes.Cover}
                    src={this.props.item.img}
                    alt={this.props.item.name}
                  />
                </CardMedia>
              </Grid>
              <Grid item xs={9}>
                <CardContent className={classes.CardContent}>
                  <div className={classes.Details}>
                    <h3 className={classes.CardTitle}>
                      {this.props.item.name}
                    </h3>
                    <p className={classes.Price}>
                      Starting at {this.props.item.price}
                    </p>
                  </div>
                  <div style={{ paddingTop: "15px" }}>
                    <Fab
                      color="primary"
                      aria-label="Add"
                      className={
                        this.props.item.inCart === false
                          ? classes.AddButton
                          : classes.AddButtonActive
                      }
                      onClick={this.handleAddToCart}
                      disableTouchRipple={true}
                    >
                      {" "}
                    </Fab>
                  </div>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Hidden>
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
    minWidth: "257px",
    [theme.breakpoints.down("lg")]: {
      minWidth: "240px"
    }
  },
  Card: {
    maxWidth: "257px",
    [theme.breakpoints.down("xs")]: {
      width: "315px",
      maxWidth: "315px",
      margin: "0 auto",
      height: "72px !important"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto"
    },
    [theme.breakpoints.up("sm")]: {
      "& img": {
        minHeight: "257px",
        maxHeight: "257px",
        // marginLeft: "auto",
        // marginRight: "auto",
        objectFit: "contain"
      }
    }
  },
  CardContent: {
    display: "inline-flex",
    width: "100%",
    padding: "0px 15px"
  },
  CardTitle: {
    width: "150px",
    height: "18px",
    fontFamily: "PT Sans",
    fontSize: "12px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#434c5f",
    paddingBottom: "23px",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
      marginBottom: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  Price: {
    // width: "73px",
    // height: "12px",
    opacity: "0.55",
    fontFamily: "PT Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#79797a",
    // marginTop: "16px",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0
    }
  },
  Details: {
    width: 170
  },
  Cover: {
    width: 72,
    maxHeight: 72,
    objectFit: "contain"
  },
  AddButton: {
    width: "36px",
    height: "36px",
    objectFit: "contain",
    backgroundImage: "url('../assets/icons/add-icon.svg')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    alignContent: "center",
    boxShadow: "none"
  },
  AddButtonActive: {
    width: "36px",
    height: "36px",
    objectFit: "contain",
    backgroundImage: "url('../assets/icons/icons-active/check-icon.svg')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    alignContent: "center",
    boxShadow: "none"
  }
});

const mapStateToProps = state => {
  return {
    // items: state.categories,
    addToCart: state.addToCart,
    cart: state.cart,
    deleteFromCart: state.deleteFromCart,
    categories: state.categories
  };
};

export default connect(
  mapStateToProps,
  { addToCart, deleteFromCart, selectedCategory }
)(withStyles(styles)(Item));
