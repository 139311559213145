import React, { Component } from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { Fab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

class RemoteSubmitButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false
    };
  }

  componentDidUpdate() {
    if (this.props.cart.items.length === 0 && this.state.clicked === true) {
      this.props.history.push("/step4");
    }
    this.disabledButton();
  }
  disabledButton = () => {
    if (this.state.clicked === true) {
      if (this.props.form.createPO.submitting) {
        return true;
      }
    }
    return false;
  };
  render() {
    const { dispatch, classes } = this.props;

    return (
      <Fab
        variant="extended"
        aria-label="Delete"
        className={classes.CartBtn}
        color="secondary"
        onClick={async () => {
          await dispatch(submit("createPO"));
          this.setState({
            clicked: true
          });
        }}
        disabled={this.disabledButton()}
      >
        Submit Pack
      </Fab>
    );
  }
}

const styles = theme => ({
  CartBtn: {
    width: "268px",
    height: "64px",
    borderRadius: "4px",
    backgroundColor: "#4cb9e9",
    fontFamily: "Cabin",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.75",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      width: "328px"
    }
  }
});

const mapStateToProps = state => {
  return {
    cart: state.cart,
    form: state.form
  };
};
export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(withRouter(RemoteSubmitButton))
);
