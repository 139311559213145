// import { SubmissionError } from "redux-form";
import moment from "moment";
import _ from "lodash";
import { closeCart, submitOrder, clearCart } from "../actions";
import { store } from "../index";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

function submit(values) {
  return sleep(2000).then(() => {
    // simulate server latency
    const inStorage = JSON.parse(window.localStorage.getItem("state"));
    const items = inStorage.cart.items;

    let formatedDate = moment(values.date).format("YYYY-MM-DD");
    let order = new FormData();
    order.append("work_email", values.email);
    order.append("pack_quantity", values.amount);
    order.append("pack_budget", values.budget);
    order.append("inhands_date", formatedDate);
    order.append("comments", values.comments);
    if (values.primaryLogo !== undefined) {
    order.append("primary_logo", values.primaryLogo[0]);
    }
    if (values.secondaryLogo !== undefined) {
      order.append("secondary_logo", values.secondaryLogo[0]);
    }
    if (values.number !== undefined) {
      order.append("phone", values.number);
    }

    order.append("full_name", values.name);
    order.append("company", values.company);
    _.map(items, (elem, index) => {
      let newItem = { item: elem.id, quantity: 1 };
      order.append("items[" + index + "]item", newItem.item);
    });
    store.dispatch(submitOrder(order));
    // store.dispatch(closeCart());
    // store.dispatch(clearCart());

    return true;
  });
}

export default submit;
