import React, { Component } from "react";
import { Grid, withStyles, Fab, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Button, FormControl, Input, FormHelperText } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { sendMail } from "../actions";
import {
  required,
  email,
  date,
  length,
  numericality,
  format
} from "redux-form-validators";
import { withRouter } from "react-router-dom";

class FrontPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidUpdate() {
    if (this.state.clicked === true) {
      this.props.history.push("/step4");
    }
  }
  renderTextInput = ({ input, label, placeholder, classes, meta }) => {
    // console.log(meta);

    return (
      <FormControl fullWidth className="">
        {/* <InputLabel className={classes.PlaceholderLabel}>{label}</InputLabel> */}
        <Input
          {...input}
          error={meta.touched && meta.error && true}
          placeholder={placeholder}
        />
        {this.renderError(meta)}
      </FormControl>
    );
  };
  renderError = ({ error, touched }) => {
    if (touched && error) {
      return <FormHelperText id="component-error-text">{error}</FormHelperText>;
    }
  };
  onSubmit = formValues => {
    // console.log(formValues);
    this.props.sendMail(formValues);
    this.setState({
      clicked: true
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.root}>
        <section className={classes.Cover}>
          <Grid
            container
            alignItems="center"
            spacing={24}
            justify="center"
            className={classes.MainContainer}
          >
            <Grid item>
              <div className={classes.CoverText}>
                <h1 className={classes.CoverHeader}>
                  Before you get started...
                </h1>
                <p className={classes.ThingsToKnow}>Some things to know:</p>
                <ul className={classes.ListToKnow}>
                  <li>+ We offer 6 to 10 high-quality items per category.</li>
                  <li>+ You may choose multiple items per category.</li>
                  <li>+ The pricing reflected here is an estimate.</li>
                  <li>+ By making your picks you are not placing an order.</li>
                </ul>
                <Button
                  variant="extendedFab"
                  aria-label="Delete"
                  className={classes.StartBigBtn}
                  color="secondary"
                  component={Link}
                  to="/step1"
                  //   onClick={this.submitForm}
                >
                  START BUILDING
                </Button>

              </div>
            </Grid>
          </Grid>
        </section>
        <section className={classes.Brands}>
          <Grid
            container
            alignItems="center"
            spacing={24}
            justify="center"
            className={classes.MainContainer}
          >
            <Grid item xs={12} style={{maxWidth: "1440px"}}>
              <Grid container spacing={24}>
                <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                  <div className={classes.StepBlog}>
                    <img src="../assets/pointer.svg" alt="Pointer" />
                    <h3 className={classes.StepHeader}>MAKE PICKS</h3>
                    <p className={classes.StepDescription}>
                      Pick from our curated
                      <br /> selection of culture
                      <br /> kit items.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                  <div className={classes.StepBlog}>
                    <img src="../assets/budget.svg" alt="Budget" />
                    <h3 className={classes.StepHeader}>SET BUDGET</h3>
                    <p className={classes.StepDescription}>
                      Tell us how much you
                      <br /> want to spend per pack
                      <br /> and how many you need
                    </p>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                  <div className={classes.StepBlog}>
                    <img src="../assets/computer.svg" alt="Computer" />
                    <h3 className={classes.StepHeader}>REVIEW PACKS</h3>
                    <p className={classes.StepDescription}>
                      We'll send you mockups
                      <br /> based on your selections
                      <br /> and logo to review
                    </p>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                  <div className={classes.StepBlog}>
                    <img
                      src="../assets/magnifying-g-lass.svg"
                      alt="Magnifying glass"
                    />
                    <h3 className={classes.StepHeader}>PLACE ORDER</h3>
                    <p className={classes.StepDescription}>
                      Once you are happy with
                      <Hidden only={["xs"]}>
                        <br />
                      </Hidden>{" "}
                      the pack mockups, we'll
                      <Hidden only={["xs"]}>
                        <br />
                      </Hidden>{" "}
                      get started on your order
                    </p>
                  </div>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </section>
        <section className={classes.Contact} id="contact">
          <h1 className={classes.ContactHeader}>SEND US A MESSAGE</h1>
          <div className={classes.FormContainer}>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <p className={classes.label}>Name </p>
              <Field
                name="name"
                component={this.renderTextInput}
                classes={classes}
                placeholder="Jane Doe"
              />
              <p className={classes.label}>Email </p>
              <Field
                name="email"
                component={this.renderTextInput}
                classes={classes}
                placeholder="example@email.com"
              />
              <p className={classes.label}>Subject </p>
              <Field
                name="subject"
                component={this.renderTextInput}
                classes={classes}
                placeholder="Hello there!"
              />
              <p className={classes.label}>Message </p>
              <Field
                name="message"
                component={this.renderTextInput}
                classes={classes}
                placeholder="Leave a message"
              />
              <div className={classes.FormAction}>
                <Fab
                  variant="extended"
                  type="submit"
                  aria-label="Delete"
                  className={classes.SendBigBtn}
                  color="secondary"
                  //   component={Link}
                  //   to="/step1"
                  //   onClick={this.submitForm}
                >
                  SEND
                </Fab>
              </div>
            </form>
          </div>
          <div className={classes.Footertext}>
            <p>
              © 2019 by LivingHR Market   -   market@livinghr.com
            </p>
          </div>
        </section>
      </main>
    );
  }
}

const styles = theme => ({
  root: {
    // display: "flex"
    paddingTop: "107px",
    paddingBottom: "100px",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "10px"
    }
  },
  Cover: {
    [theme.breakpoints.up("sm")]: {
      backgroundImage: "url(../assets/artwork.svg)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      backgroundSize: "contain",
      width: "100%"
    }
  },
  CoverText: {
    // paddingLeft: "125px",
    [theme.breakpoints.down("lg")]: {
      // paddingLeft: "125px",
      minWidth: "1280px",
      minHeight: "480px"
    },
    [theme.breakpoints.up("sm")]: {
      // paddingLeft: "125px",
      minWidth: "1260px",
      minHeight: "350px",
      paddingLeft: "20px",
      paddingBottom: "60px",
      paddingTop: "20px"
    },
    [theme.breakpoints.up("md")]: {
      // paddingLeft: "125px",
      minWidth: "1260px",
      minHeight: "350px",
      paddingLeft: "20px",
      paddingBottom: "175px",
      paddingTop: "100px"
    },
    [theme.breakpoints.up("xl")]: {
      // paddingLeft: "125px",
      minWidth: "1440px",
      minHeight: "766px",
      paddingTop: "120px"
    },
    [theme.breakpoints.down("sm")]: {
      // paddingLeft: "125px",
      minWidth: "768px",
      minHeight: "350px",
      paddingLeft: "20px"
    },
    [theme.breakpoints.down("xs")]: {
      // paddingLeft: "125px",
      minWidth: "300px",
      minHeight: "346px",
      paddingLeft: "21px"
    }
  },
  CoverHeader: {
    fontFamily: "FuturaLTPro",
    fontSize: "56px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4cb9e9",
    marginBottom: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "32px"
    }
  },
  // MainContainer: {
  //   // margin: "6px",
  //   // paddingTop: "107px",
  //   // paddingBottom: "100px"
  //   maxWidth: "1440px"
  //   // margin: "0% 25%"
  // },
  ThingsToKnow: {
    fontFamily: "PT Sans",
    fontWeight: "bold",
    fontSize: "20px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.8",
    letterSpacing: "normal",
    color: "#000000",
    marginTop: "0",
    marginBottom: "0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px"
    }
  },
  ListToKnow: {
    fontFamily: "PT Sans",
    fontSize: "18px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.8",
    letterSpacing: "normal",
    color: "#000000",
    paddingBottom: "10px",
    paddingLeft: "0",
    marginTop: 0,
    listStyle: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px"
    }
  },
  StartBigBtn: {
    width: "288px",
    height: "64px",
    borderRadius: "4px",
    backgroundColor: "#4cb9e9",
    fontFamily: "Cabin",
    fontSize: "18px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.75",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      width: "344px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "324px"
    }
  },
  CatalogueText: {
    fontFamily: "FuturaLTPro",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4cb9e9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px"
    }
  },
  CatalogueLink: {
    fontFamily: "Futura",
    fontSize: "20px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4cb9e9",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px"
    }
  },
  Brands: {
    backgroundColor: "#fafafa",
    padding: "96px 20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "40px 20px",
      padding: "35px 20px",
    }
  },
  StepBlog: {
    textAlign: "center",
    "& img":{
      [theme.breakpoints.down("sm")]: {
        width: "47px",
        height: "64px",
        objectFit: "contain",
      },
      [theme.breakpoints.down("xs")]: {
        width: "70px",
        height: "64px",
        objectFit: "contain",
      }
    }
  },
  StepHeader: {
    fontFamily: "Cabin",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#4cb9e9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  StepDescription: {
    fontFamily: "FuturaLTPro",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.21",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#000000"
  },
  BrandsHeader: {
    fontFamily: "Futura",
    fontSize: "12px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#4cb9e9",
    textTransform: "uppercase",
    marginBottom: "8px",
    marginTop: "20px"
  },
  BrandsLogos: {
    textAlign: "center"
  },
  LogosOne: {
    marginBottom: "6px",

    [theme.breakpoints.down("xs")]: {
      "& img": {
        width: "100%"
      }
    }
  },
  LogosTwo: {
    [theme.breakpoints.down("xs")]: {
      "& img": {
        width: "100%"
      }
    }
  },
  Contact: {
    padding: "96px 20px",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 20px"
    }
  },
  ContactHeader: {
    fontFamily: "Cabin",
    fontSize: "56px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#4cb9e9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    }
  },
  FormContainer: {
    width: "588px",
    margin: "0 auto",

    [theme.breakpoints.down("xs")]: {
      width: "300px"
    }
  },
  label: {
    width: "100%",
    height: "25px",
    paddingTop: "15px",
    fontFamily: "Cabin",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000000"
  },
  PlaceholderLabel: {
    width: "100%",
    height: "21px",
    opacity: "0.54",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4cb9e9"
  },
  FormAction: {
    textAlign: "center",
    padding: "40px",
    [theme.breakpoints.down("xs")]: {
      padding: "40px 0"
    }
  },
  Footertext: {
    fontFamily: "PT Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#79797a",
    textTransform: "uppercase"
  },
  SendBigBtn: {
    width: "384px",
    height: "64px",
    borderRadius: "4px",
    backgroundColor: "#4cb9e9",
    fontFamily: "Cabin",
    fontSize: "18px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.75",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    boxShadow: "none",
    [theme.breakpoints.down("xs")]: {
      width: "305px"
    }
  }
});

const validations = {
  email: [required(), email()],
  name: [
    required(),
    length({ min: 3 }),
    format({
      with: /^[a-zA-Z\s]*$/i,
      message: { defaultMessage: "Letters only" }
    })
  ],
  subject: [
    required(),
    length({ min: 3 })
    // format({
    //   with: /^[a-zA-Z\s]*$/i,
    //   message: { defaultMessage: "Letters only" }
    // })
  ],
  message: [
    required(),
    length({ min: 3 })
    // format({
    //   with: /^[a-zA-Z\s]*$/i,
    //   message: { defaultMessage: "Letters only" }
    // })
  ]
};

const validate = values => {
  const errors = {};
  for (let field in validations) {
    let value = values[field];
    errors[field] = validations[field]
      .map(validateField => {
        return validateField(value, values);
      })
      .find(x => x);
  }
  return errors;
};
// const mapStateToProps = state => {
//   console.log("After submit  ", state);

//   return {
//     sendMail: state.sendMail
//   };
// };
const SendMail = reduxForm({
  form: "contactForm",
  validate
})(withStyles(styles)(FrontPage));
export default connect(
  null,
  { sendMail }
)(withRouter(SendMail));
