import React from "react";
import { withStyles } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import ChevronRight from "@material-ui/icons/ChevronRight";

const Breadcrumb = props => {
  const { classes } = props;
  const handleLocation =
    props.location.pathname === "/step1" ? (
      <span>{props.currentPage}</span>
    ) : props.location.pathname === "/step2" ? (
      <React.Fragment>
        <Link to="/step1">Swag</Link>
        <span>
          <ChevronRight className={classes.ArrowRight} />
        </span>
        {props.currentPage}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Link to="/step1">Swag</Link>
        <span>
          <ChevronRight className={classes.ArrowRight} />
        </span>
        <Link to="/step2">Bonus Items</Link>
        <span>
          <ChevronRight className={classes.ArrowRight} />
        </span>
        Swag Pack Wrap - Up Questions
      </React.Fragment>
    );

  return (
    <div>
      <p className={classes.Breadcrumb}>{handleLocation}</p>
    </div>
  );
};

const styles = theme => ({
  Breadcrumb: {
    fontFamily: "PT Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000000",
    paddingLeft: "7px",
    marginBottom: 10,

    "& a": {
      color: "#000000"
    },
      [theme.breakpoints.down("sm")]: {
          paddingLeft: "40px"
      }
  },
  ArrowRight: {
    fontSize: "12px"
  }
});

export default withStyles(styles)(withRouter(Breadcrumb));
