import React, { Component } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { selectedCategory } from "../actions";
import { connect } from "react-redux";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";

import Item from "./Item";

class CategoryWrapper extends Component {
  constructor(props) {
    super(props);
    this.cat = React.createRef();
  }
  componentDidMount() {
    if (
      (this.props.width === "xs" || this.props.width === "sm") &&
      this.props.name === "Bonus Items"
    ) {
      let title = document.getElementById("cat" + this.props.category);

      title.style.display = "none";
    }
  }
  createMarkup = label => {
    return { __html: label };
  };
  render() {
    const { classes } = this.props;

    const handleHeading = (
      <h1
        className={classes.BlockTitle}
        ref={this.cat}
        id={"cat" + this.props.category}
        dangerouslySetInnerHTML={this.createMarkup(this.props.label)}
      />
    );

    return (
      <div className={classes.root} id={this.props.id}>
        {handleHeading}
        <div className={classes.container}>
          <Grid container spacing={24}>
            {this.props.items.map((item, index) => (
              <Item key={index} category={this.props.category} item={item} />
            ))}
          </Grid>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
    paddingTop: 0
  },
  container: {
    // paddingLeft: "20px"
  },
  BlockTitle: {
    height: "31px",
    fontFamily: "Cabin",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4cb9e9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      width: "630px",
      paddingLeft: "32px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      width: "340px",
      marginBottom: "24px",
      paddingLeft: "23px"
    }
  },
  BlockTitle1: {
    height: "31px",
    fontFamily: "Cabin",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4cb9e9",
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      width: "630px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      width: "375px"
    }
  },
  BoldText: {
    fontFamily: "Cabin",
    fontWeight: "bold"
  },
  Sticker: {
    height: "18px",
    fontFamily: "Cabin",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
    color: "#4cb9e9",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center"
    }
  }
});

export default connect(
  null,
  { selectedCategory }
)(
  compose(
    withStyles(styles),
    withWidth()
  )(CategoryWrapper)
);
