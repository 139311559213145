import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fab } from "@material-ui/core";
import { Drawer, Hidden, withWidth, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { connect } from "react-redux";
import compose from "recompose/compose";

//Components
import CartElement from "./CartElement";
import RemoteSubmitButton from "./RemoteSubmitButton";
import { openCart, closeCart, fetchItems, submitOrder } from "../actions";

const drawerWidth = 300;

class Cart extends Component {
  constructor(props){
    super(props);
    this.state = {
     hasErrors: false,
     submitFailed: false
    }
  }
  componentWillMount() {
    if (this.props.width !== "xs" && this.props.width !== "sm") {
      this.props.items.length > 0 ? this.openCart() : this.closeCart();
    }
  }
  componentDidUpdate(previousProps, previousState) {
    let submitFailed = '';
    let syncErrors = '';
    let errorsFlag = false;

    if (previousProps.items !== this.props.items) {
      if (this.props.width !== "xs" && this.props.width !== "sm") {
        this.props.items.length > 0 ? this.openCart() : this.closeCart();
      }
    }

    if(this.props.form.createPO){
      if(previousProps.form.createPO !== this.props.form.createPO){
        syncErrors = this.props.form.createPO.syncErrors
        const values = Object.values(syncErrors)
        values.map((v)=>{
          if(v !== undefined ){
            errorsFlag = true;
          }
        });
        submitFailed = errorsFlag;
        this.setState({
          submitFailed
        })
      }
    }
  }
  openCart = () => {
    this.props.openCart();
  };
  closeCart = () => {
    this.props.closeCart();
  };

  handleCartActions = () => {
   const {classes, step,items } = this.props;
   const { submitFailed } = this.state;
    if(step === '/step4'){
      return <React.Fragment>
        {submitFailed && <div className={classes.ErrorPopUp} >
          <Grid container >
            <Grid item xs={2}>
              <div className={classes.ErrorImgContainer}>
              <img src="/assets/round-error-24-px.svg" alt="Errors"/>
              </div>
            </Grid>
            <Grid item xs={10}>
              <p className={classes.ErrorDesc}>
                <b>Oh no!</b> Some fields are incorrect or missing.</p>
            </Grid>
          </Grid>

        </div>}
        <RemoteSubmitButton />
      </React.Fragment>
    }
    return <Fab
        variant="extended"
        aria-label="Delete"
        className={classes.CartBtn}
        color="secondary"
        component={Link}
        to={step}
        onClick={this.closeCart}
        disabled={items.length > 0 ? false : true}
    >
      Continue
    </Fab>
  }

  render() {
    const { classes } = this.props;

    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={this.props.open}
        SlideProps={{ unmountOnExit: true }}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.CartTitle}>
            Your Swag Pack{" "}
            <Hidden only={["md", "lg", "xl"]}>
              <ClearIcon style={{ float: "right" }} onClick={this.closeCart} />
            </Hidden>
          </div>
        </div>
        <div className={classes.ElementsContainer}>
          <ul className={classes.CartList}>
            {this.props.items.map((item, index) => (
              <CartElement key={index} item={item} />
            ))}
          </ul>
        </div>
        <div className={classes.CartActions}>
          {this.handleCartActions()}
        </div>
      </Drawer>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#fafafa",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "115px 0 0 0",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      padding: "15px 0 0 0"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px 0 0 0"
    }
  },
  ElementsContainer: {
    height: "70%",
    overflowY: "auto",
    overflowX: "hidden"
  },
  CartList: {
    paddingLeft: "0px",
    marginTop: "0px"
  },
  CartTitle: {
    textTransform: "uppercase",
    width: "300px",
    height: "18px",
    padding: "5px 25px 30px 15px",
    fontFamily: "PT Sans",
    fontSize: "12px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000000",
    borderBottom: "1px solid #d4d9e2",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3
  },
  CartActions: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    padding: "1rem",
    textAlign: "center"
  },
  CartBtn: {
    width: "268px",
    height: "64px",
    borderRadius: "4px",
    backgroundColor: "#4cb9e9",
    fontFamily: "Cabin",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.75",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    boxShadow: "none",
    [theme.breakpoints.down("xs")]: {
      width: "300px"
    }
  },
  ErrorPopUp:{
    width: "268px",
    height: "40px",
    borderRadius: "4px",
    backgroundColor: "#ea3a42",
    marginBottom: "8px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "15px"
    }
  },
  ErrorImgContainer:{
    width: "24px",
    height: "24px",
    objectFit: "contain",
    margin: "10px auto"
  },
  ErrorDesc:{
  fontFamily: "PT Sans",
  fontSize: "11px",
  fontWeight: "normal",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "1.73",
  letterSpacing: "normal",
  color: "#ffffff",
  textAlign: "left"
  }
});

const mapStateToProps = state => {
  return {
    form: state.form,
    items: state.cart.items,
    open: state.cart.open,
    closeCart: state.closeCart,
    openCart: state.openCart,
    order: state.order
  };
};

export default connect(
  mapStateToProps,
  { openCart, closeCart, fetchItems, submitOrder }
)(
  compose(
    withStyles(styles, { withTheme: true }),
    withWidth()
  )(Cart)
);
