import DashBoardAPI from "../apis/DashboardAPI";

// import _ from "lodash";
export const fetchItems = initialState => async dispatch => {
  const categories = await DashBoardAPI.get("/category/");
  // console.log("API: ", categories.data);
  dispatch({
    type: "FETCH_ITEMS",
    payload: categories.data
  });
};

export const submitOrder = order => async dispatch => {
  // console.log("I'm here");
  var headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  await DashBoardAPI.post("/order/", order, {
    headers
  })
    .then(response => {
      // console.log("Response: ", typeof response.status);

      if (response.status === 201) {
        dispatch({
          type: "SUBMIT_ORDER",
          payload: response.data
        });
        dispatch({
          type: "CLOSE_CART",
          payload: false
        });
        dispatch({
          type: "CLEAR_CART"
        });
      }
    })
    .catch(error => {
      // localStorage.setItem("status", error.status);
      // console.log("Error: ", error);
    });
};

export const selectedCategory = (id, name) => async dispatch => {
  // console.log("ACTION CATEGORY_SELECTED: ", name);
  dispatch({
    type: "CATEGORY_SELECTED",
    payload: {
      id,
      name
    }
  });
};

export const toggleCategoriesBar = () => async dispatch => {
  dispatch({
    type: "TOGGLE_CATEGORIES_BAR"
  });
};

export const sendMail = values => async dispatch => {
  var headers = {
    "Content-Type": "application/json"
  };
  await DashBoardAPI.post("/contact_forms/", values, {
    headers
  })
    .then(response => {
      // console.log("Response: ", response);
      dispatch({
        type: "SUBMIT_POST",
        payload: response.data
      });
    })
    .catch(error => {
      // console.log("Error: ", error.response.data);
    });
};

export const addToCart = item => async dispatch => {
  dispatch({
    type: "ADD_TO_CART",
    payload: {
      item: {
        id: item.id,
        category: item.category,
        name: item.name,
        img: item.img,
        price: item.price,
        inCart: true
      }
    }
  });
};

export const openCart = () => async dispatch => {
  dispatch({
    type: "OPEN_CART",
    payload: true
  });
};

export const closeCart = () => async dispatch => {
  dispatch({
    type: "CLOSE_CART",
    payload: false
  });
};

export const clicked = click => async dispatch => {
  dispatch({
    type: "CLICKED",
    payload: click
  });
};

export const clearCart = () => async dispatch => {
  // console.log("someone called me");

  dispatch({
    type: "CLEAR_CART"
  });
};
//export const fetchUser = id => dispatch => _fetchUser(id, dispatch);
// const _fetchUser = _.memoize(async (id, dispatch) => {
//     const response = await jsonPlaceholder.get(`/userrs/${id}`);
//     dispatch({type: 'FETCH_USER', payload: response.data});
// });

export const deleteFromCart = item => async dispatch => {
  dispatch({
    type: "DELETE_FROM_CART",
    payload: {
      item: {
        id: item.id,
        category: item.category,
        name: item.name,
        img: item.img,
        price: item.price,
        inCart: false
      }
    }
  });
};
