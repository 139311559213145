import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../styles/App.scss";

//Components
import Header from "./Header";
import FrontPage from "./FrontPage";
import MainContent from "./MainContent";
import BonusContent from "./BonusContent";
import FormContent from "./FormContent";
import ThanksContent from "./ThanksContent";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Header />
          <Switch>
            <Route path="/" component={FrontPage} exact />
            <Route path="/step1" component={MainContent} exact />
            <Route path="/step2" component={BonusContent} />
            <Route path="/step3" component={FormContent} />
            <Route path="/step4" component={ThanksContent} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
