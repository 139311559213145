import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Inventory from "../images/icons/inventory.svg";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from 'classnames';

import {
  Tabs,
  Tab,
  Toolbar,
  AppBar,
  Grid,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Fab
} from "@material-ui/core";
import compose from "recompose/compose";
import withWidth from "@material-ui/core/withWidth";
import Hidden from "@material-ui/core/Hidden";
import CloseIcon from "@material-ui/icons/Close";
import { openCart, toggleCategoriesBar } from "../actions";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLocation: "",
      top: false
    };
    this.props.history.listen((location, action) => {
      this.setState({
        currentLocation: location.pathname
      });
    });
  }

  componentWillMount() {
    const currentLocation = this.props.location.pathname;
    this.setState({
      currentLocation: currentLocation
    });
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  redirectOut = url => {
    window.location.href = url;
  };

  openCart = () => {
    this.props.openCart();
  };

  toggleCategoriesBar = () => {
    this.props.toggleCategoriesBar();
  };

  render() {
    const { classes } = this.props;
    const logo = <img className={classes.logo} alt="SwagUp" src="/logo.svg" />;
    const cartCounter =
      this.props.cart.length > 0 ? (
        <div className={classes.MobileCartBtnActive} onClick={this.openCart}>
          <div className={classes.CartBtnTextActive}>
            <Hidden only={["sm"]}>{this.props.cart.length}</Hidden>
            <Hidden only={["xs"]}>
              <img
                src="../assets/icons/icons-active/inventory.svg"
                alt="Inventory"
              />
              <span style={{ paddingTop: "3px", paddingLeft: "5px" }}>
                Your Swag Pack ({this.props.cart.length})
              </span>
            </Hidden>
          </div>
        </div>
      ) : (
        <div className={classes.MobileCartBtn} onClick={this.openCart}>
          <div className={classes.CartBtnText}>
            <img src="../assets/icons/inventory.svg" alt="Inventory" />{" "}
            <Hidden only={["xs"]}>
              <span style={{ paddingTop: "3px", paddingLeft: "5px" }}>
                Your Swag Pack (0)
              </span>
            </Hidden>
          </div>
        </div>
      );

    return (
      <React.Fragment>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Hidden only={["xs", "sm"]}>
            <Grid container alignItems="center" spacing={24} justify="center">
              <Grid item>
                <Toolbar className={classes.Nav}>
                  <a href="https://livinghrmarket.com/">{logo}</a>
                  <Tabs className={classes.menuOptions}>
                    <Tab
                      className={classes.MenuItem}
                      label="Build a Culture Kit"
                      // value="https://www.swagup.com/careers"
                      onClick={() => {
                        this.redirectOut("/step1");
                      }}
                    />
                    <Tab
                      className={classes.MenuItem}
                      label="Order Individual Kit Items"
                      onClick={() => {
                        this.redirectOut("https://livinghrmarket.com/products/individual-culture-items");
                      }}
                    />
                    <Tab
                      className={classNames(classes.MenuItem, classes.Faq)}
                      label="Faq"
                      onClick={() => {
                        this.redirectOut("https://livinghrmarket.com/pages/faqs");
                      }}
                    />
                    <Tab
                      className={classes.MenuItem}
                      label="Contact"
                      onClick={() => {
                        this.redirectOut("https://livinghrmarket.com/pages/contact");
                      }}
                    />
                  </Tabs>
                </Toolbar>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden only={["md", "lg", "xl"]}>
            <Grid container>
              <Grid item xs={6} sm={6} className={classes.MobileLogoWrapper}>
                <a href="/">{logo}</a>
              </Grid>
              <Grid item xs={6} sm={6} className={classes.MobileMenuWrapper}>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.toggleDrawer("top", true)}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              {this.state.currentLocation !== "/step4" &&
                this.state.currentLocation !== "/" && (
                  <Grid item xs={12} sm={12}>
                    <Grid container>
                      <Grid item xs={10} sm={6}>
                        <div
                          className={classes.MobileCategoryBtn}
                          onClick={this.toggleCategoriesBar}
                        >
                          <div className={classes.CategoryBtnText}>
                            {this.state.currentLocation === "/step2" ? (
                              <React.Fragment>
                                <Link to="/step1">
                                  <ChevronLeft />
                                </Link>

                                <h1 className={classes.BlockTitle}>
                                  <span className={classes.BoldText}>Bonus Items!</span>{" "}
                                  Create the ultimate swag pack
                                </h1>
                              </React.Fragment>
                            ) : this.state.currentLocation === "/step3" ? (
                              <React.Fragment>
                                <Link to="/step2">
                                  <ChevronLeft />
                                </Link>
                                <h1 className={classes.BlockTitle}>
                                  Swag Pack Wrap - Up Questions
                                </h1>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <span>{this.props.categoryName}</span>
                                <ChevronRight className={classes.ArrowRight} />
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={2} sm={6}>
                        {cartCounter}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </Hidden>
        </AppBar>
        <Drawer
          anchor="top"
          className={classes.drawer}
          open={this.state.top}
          onClose={this.toggleDrawer("top", false)}
        >
          <div
            tabIndex={0}
            role="button"
            // onClick={this.toggleDrawer("left", false)}
            // onKeyDown={this.toggleDrawer("left", false)}
            style={{height: "-webkit-fill-available"}}
          >
            <div className={classes.drawerCatHeader}>
              <Grid container className={classes.MobileMenu}>
                <Grid item xs={6} sm={6} className={classes.logoGrid}>
                  {logo}
                </Grid>
                <Grid item xs={6} sm={6}>
                  <IconButton
                    className={classes.button}
                    aria-label="Close"
                    onClick={this.toggleDrawer("top", false)}
                    style={{ float: "right" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <div className={classes.fullList}>
                    <List component="nav">
                      <ListItem button>
                        <ListItemText
                            className={classes.MobileMenuText}
                            primary="Build a Culture Kit"
                            onClick={() => {
                              this.redirectOut("/step1");
                            }}
                        />
                      </ListItem>
                      <ListItem button>
                        <ListItemText
                          className={classes.MobileMenuText}
                          primary="Order Individual Kit Items"
                          onClick={() => {
                            this.redirectOut("https://livinghrmarket.com/products/individual-culture-items");
                          }}
                        />
                      </ListItem>
                      <ListItem button>
                        <ListItemText
                          className={classes.MobileMenuText}
                          primary="FAQ"
                          onClick={() => {
                            this.redirectOut("https://livinghrmarket.com/pages/faqs");
                          }}
                        />
                      </ListItem>
                      <ListItem button>
                        <ListItemText
                          className={classes.MobileMenuText}
                          primary="Contact"
                          onClick={() => {
                            this.redirectOut("https://livinghrmarket.com/pages/contact");
                          }}
                        />
                      </ListItem>
                    </List>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/*<Divider />*/}
          </div>
        </Drawer>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    borderBottom: "1px solid #d4d9e2",
    [theme.breakpoints.down("xs")]: {
      zIndex: theme.zIndex.drawer - 1
    },
    [theme.breakpoints.down("sm")]: {
      zIndex: theme.zIndex.drawer - 1
    }
  },
  Nav: {
    height: "107px",
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
    minWidth: "1280px",
    display: "flex",
    [theme.breakpoints.up("xl")]: {
      minWidth: "1440px",
      paddingLeft: "0 !important"
    }
  },
  logoGrid:{
    textAlign: 'left',
    paddingLeft:'40px',
  },
  logo:{
    width: "78px",
    height: "74px",
    objectFit: "contain",
  },
  menuOptions:{
    position: "absolute",
    right: 0
  },
  MobileMenuWrapper: {
    textAlign: "right",
    padding: "30px 40px 0 40px !important"
  },
  MobileLogoWrapper: {
    padding: "30px 40px 0 40px !important",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 0 0 27px !important"
    }
  },
  Inventory: {
    BackgroundImage: "url(" + Inventory + ")"
  },
  MobileCartBtn: {
    height: "56px",
    border: "1px solid #d4d9e2",
    textAlign: "center"
  },
  MobileCartBtnActive: {
    height: "56px",
    border: "1px solid #d4d9e2",
    textAlign: "center",
    backgroundColor: "#4cb9e9"
  },
  BlockTitle: {
    width: "181px",
    height: "41px",
    fontFamily: "Cabin",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4cb9e9",
    margin: "-42px auto"
  },
  BoldText: {
    fontFamily: "Cabin",
    fontWeight: "bold"
  },
  CartBtnText: {
    display: "inline-flex",
    margin: "20px auto",
    // width: "150px",
    fontFamily: "FuturaLTPro",
    fontSize: "14px",
    color: "#434c5f"
  },
  CartBtnTextActive: {
    display: "inline-flex",
    margin: "20px auto",
    fontFamily: "FuturaLTPro",
    fontSize: "14px",
    color: "#FFFFFF"
  },
  MobileCategoryBtn: {
    height: "56px",
    border: "1px solid #d4d9e2"
  },
  CategoryBtnText: {
    padding: "20px 42px",
    fontFamily: "FuturaLTPro",
    fontSize: "14px",
    color: "#434c5f",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 28px"
    }
  },
  ArrowRight: {
    paddingBottom: "3px",
    float: "right",
    fontSize: "24px"
  },
  StartBtn: {
    width: "180px",
    height: "48px",
    borderRadius: "4px",
    backgroundColor: "#3577d4",
    fontFamily: "Futura",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.75",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    boxShadow: "none"
  },
  MobileMenu: {
    padding: "20px",
    textAlign: "center"
  },
  MobileBtnItem: {
    padding: "20px 0"
  },
  MobileStartBtn: {
    width: "288px",
    height: "48px",
    borderRadius: "4px",
    backgroundColor: "#4cb9e9",
    fontFamily: "Futura",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.75",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  },
  MobileMenuText: {
    "& span": {
      fontFamily: "PT Sans",
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#000000"
    }
  },
  MenuItem: {
    fontFamily: "Cabin !important",
    fontSize: "16px !important",
    fontWeight: "bold !important",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "right !important",
    color: "#000000 !important",
    textTransform:"capitalize",
    opacity: "inherit",
    [theme.breakpoints.up("md")]: {
      minWidth: "30px !important"
    },
    [theme.breakpoints.up("xl")]: {
      // minWidth: "160px !important"
    }
  },
  Faq: {
    // maxWith: "40px !important",
    textTransform: "uppercase !important"
  },
});

const mapStateToProps = state => {
  return {
    cart: state.cart.items,
    openCart: state.openCart,
    toggleCategoriesBar: state.category.toggleCategoriesBar,
    // categories: state.categories,
    categoryName: state.category.categoryName
  };
};

export default connect(
  mapStateToProps,
  { openCart, toggleCategoriesBar }
)(
  compose(
    withStyles(styles, { withTheme: true }),
    withWidth()
  )(withRouter(Header))
);
