import _ from "lodash";
const ItemReducer = (state = [], action) => {
  switch (action.type) {
    case "FETCH_ITEMS":
      // console.log("Items LocalStore: ", state);
      // console.log("Items Payload: ", action.payload);
      if (state.length === 0) {
        return action.payload;
      }
      if (action.payload !== undefined) {
        let newState = action.payload;

        _.map(newState, (newStateCategory, index) => {
          let match = _.find(state, ["id", newStateCategory.id]);
          // console.log("Matches: ", match);
          if (match !== undefined) {
            _.map(match.items, matchItem => {
              if (matchItem.inCart === true) {
                let itemToChange = _.find(newStateCategory.items, [
                  "id",
                  matchItem.id
                ]);
                if (itemToChange !== undefined) {
                  itemToChange.inCart = matchItem.inCart;
                }
              }
              //  _.map(newStateCategory.items, newStateCategoryItems=>{
              //    if()
              //  });
            });
          }
        });
        // const merged = _.intersection(state, action.payload);
        // console.log("Merged: ", merged);

        return newState;
      }
      return state;
    case "ADD_TO_CART":
      let newState = state.slice();
      _.map(newState, c => {
        _.map(c.items, item => {
          if (
            c.id === action.payload.item.category &&
            item.id === action.payload.item.id
          ) {
            item.inCart = action.payload.item.inCart;
          }
        });
      });
      return newState;
    case "DELETE_FROM_CART":
      newState = state.slice();
      _.map(newState, c => {
        _.map(c.items, item => {
          if (
            c.id === action.payload.item.category &&
            item.id === action.payload.item.id
          ) {
            item.inCart = action.payload.item.inCart;
          }
        });
      });

      return newState;
    case "CLEAR_CART":
      newState = state.slice();
      _.map(newState, c => {
        _.map(c.items, item => {
          item.inCart = false;
        });
      });
      return newState;
    default:
      return state;
  }
};

export default ItemReducer;
