import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Grid, withStyles, Fab } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { connect } from "react-redux";
import { fetchItems, selectedCategory, clicked } from "../actions";
import _ from "lodash";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import zenscroll from "zenscroll";

//Components
import CategoriesBar from "./CategoriesBar";
import Breadcrumb from "./Breadcrumb";
import CategoryWrapper from "./CategoryWrapper";
import Cart from "./Cart";

class MainContent extends Component {
  constructor(props) {
    super(props);
    this.handleScrollToElement = this.handleScrollToElement.bind(this);
    this.doSomething = this.doSomething.bind(this);
    this.myRef = React.createRef();
    this.state = {
      clicked: "false"
    };
  }
  componentDidMount() {
    const topCategory = this.props.categories[0];
    this.props.selectedCategory(topCategory.id, topCategory.CatName);
    var last_known_scroll_position = 0;
    var ticking = false;
    window.addEventListener(
      "scroll",
      e => {
        last_known_scroll_position = window.scrollY;
        if (!ticking) {
          window.requestAnimationFrame(() => {
            this.doSomething(last_known_scroll_position);
            ticking = false;
          });
        }
        ticking = true;
      },
      false
    );
  }

  componentDidUpdate() {
    if (this.props.width === "xs") {
      if (this.props.cart.items.length > 0) {
        document.getElementById("mainContinue").style.display = "block";
      } else {
        document.getElementById("mainContinue").style.display = "none";
      }
    }
    this.handleScrollToElement();
  }
  componentWillUnmount() {
    var last_known_scroll_position = 0;
    var ticking = false;
    window.removeEventListener(
      "scroll",
      e => {
        last_known_scroll_position = window.scrollY;
        if (!ticking) {
          window.requestAnimationFrame(() => {
            this.doSomething(last_known_scroll_position);
            ticking = false;
          });
        }
        ticking = true;
      },
      false
    );
  }

  doSomething(scroll_pos) {
    let catRefs = [];
    _.map(this.props.categories, (element, index) => {
      catRefs.push("cat" + element.id);
    });

    catRefs.map((cat, index) => {
      const tesNode = ReactDOM.findDOMNode(this.refs[cat]);
      if (tesNode == null) {
        return;
      }
      let cat_pos = tesNode.offsetTop - scroll_pos;

      if (100 < cat_pos && cat_pos < 200) {
        const categories = this.props.categories;
        let catActive = _.find(categories, function(o) {
          return o.id == cat.slice(-2);
        });
        if (this.props.isClicked == false) {
          this.props.selectedCategory(catActive.id, catActive.CatName);
        }
      }
    });
  }

  handleScrollToElement() {
    if (this.props.isClicked === true) {
      if (this.props.activeCategory !== null) {
        const cat = "cat" + this.props.activeCategory;
        const tesNode = ReactDOM.findDOMNode(this.refs[cat]);

        // window.scrollTo({
        //   top: tesNode.offsetTop - 107,
        //   behavior: "smooth"
        // });
        zenscroll.toY(tesNode.offsetTop - 107);
        setTimeout(() => {
          this.props.clicked(false);
        }, 1200);
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.root}>
        <Grid container spacing={24} className={classes.MainContainer}>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <CategoriesBar categories={this.props.categories} />
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Hidden only={["xs"]}>
              <Breadcrumb
                currentPage="Swag"
                currentPath={this.props.location.pathname}
              />
            </Hidden>
            <div ref={this.myRef} />
            {this.props.categories.map((category, index) => {
              return (
                category.is_bonus !== true && (
                  <CategoryWrapper
                    key={index}
                    name={category.CatName}
                    category={category.id}
                    items={category.items}
                    label={category.label}
                    ref={"cat" + category.id}
                  />
                )
              );
            })}
            <Hidden only={["sm", "md", "lg", "xl"]}>
              <div className={classes.ScreenAction} id="mainContinue">
                <Fab
                  variant="extended"
                  aria-label="Next"
                  className={classes.BonusButton}
                  color="secondary"
                  component={Link}
                  to="/step2"
                >
                  Continue
                </Fab>
              </div>
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Cart step="/step2" />
          </Grid>
        </Grid>
      </main>
    );
  }
}

const styles = theme => ({
  root: {
    // display: "flex"
  },
  MainContainer: {
    // margin: "6px",
    paddingTop: "140px",
    paddingBottom: "100px"
  },
  ScreenAction: {
    textAlign: "center",
    padding: "28px 28px 20px 28px",
    position: "fixed",
    bottom: 0
  },
  BonusButton: {
    width: "315px",
    height: "56px",
    borderRadius: "4px",
    backgroundColor: "#4cb9e9",
    fontFamily: "Cabin",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.75",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  }
});

const mapStateToProps = state => {
  return {
    categories: state.categories,
    activeCategory: state.category.selectedCategory,
    isClicked: state.category.clicked,
    cart: state.cart
  };
};

export default connect(
  mapStateToProps,
  { fetchItems, selectedCategory, clicked }
)(
  compose(
    withStyles(styles),
    withWidth()
  )(MainContent)
);
