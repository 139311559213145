import _ from "lodash";
const CartReducer = (state = { items: [], open: false }, action) => {
  switch (action.type) {
    case "FETCH_ITEMS":
      if (action.payload !== undefined) {
        let newState = Object.assign({}, state);
        let finalItems = newState.items;

        // console.log("Cart Received Payload: ", action.payload);
        // console.log("Cart Initial State: ", newState);

        _.map(newState.items, (newStateItem, index) => {
          // console.log("NewState: ", newState.items);
          // console.log("NewStateItem: ", newStateItem, "index ", index);
          let itemCategoryExist = _.find(action.payload, [
            "id",
            newStateItem.category
          ]);

          // console.log("itemCategoryExist: ", itemCategoryExist);
          // console.log("itemCategoryExist type: ", typeof itemCategoryExist);

          if (typeof itemCategoryExist === "undefined") {
            finalItems = _.filter(newState.items, o => {
              return o.id !== newStateItem.id;
            });
          } else {
            let itemExistance = _.find(itemCategoryExist.items, [
              "id",
              newStateItem.id
            ]);
            if (typeof itemExistance === "undefined") {
              // console.log("If Not exists on selected category", newStateItem);
              finalItems = _.filter(newState.items, o => {
                // console.log("IF Statement ", o.id, " == ", newStateItem.id);
                return o.id !== newStateItem.id;
              });
              // newState = Object.assign({}, newState, {
              //   items: finalItems
              // });
              // console.log(
              //   "AFTER ITEM REMOVED: ",
              //   newState.items,
              //   "FINAL ITEMS",
              //   finalItems
              // );
            }
          }
        });

        let finalState = Object.assign({}, newState, {
          items: finalItems
        });
        // console.log("Returned: ", finalState);
        return finalState;
      }
      return state;
    case "ADD_TO_CART":
      // console.log("logging cat: ", action.payload.item);
      return Object.assign({}, state, {
        items: [...state.items, action.payload.item]
      });
    case "DELETE_FROM_CART":
      let newListItems = _.filter(state.items, function(o) {
        return o.name !== action.payload.item.name;
      });
      let newState = Object.assign({}, state, {
        items: newListItems
      });
      return newState;
    case "OPEN_CART":
      return Object.assign({}, state, {
        // ...state.items,
        open: action.payload
      });

    case "CLOSE_CART":
      return Object.assign({}, state, {
        // ...state.items,
        open: action.payload
      });

    case "CLEAR_CART":
      // console.log("Deleting");
      return Object.assign({}, state, {
        // ...state.items,
        items: [],
        open: false
      });

    //   return state.filter(action => action.payload);
    default:
      return state;
  }
};

export default CartReducer;
