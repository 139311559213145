import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import ItemsReducer from "./ItemsReducer";
import CategoryReducer from "./CatagoryReducer";
import CartReducer from "./CartReducer";
import OrderReducer from "./OrderReducer";

export default combineReducers({
  categories: ItemsReducer,
  category: CategoryReducer,
  cart: CartReducer,
  form: formReducer,
  order: OrderReducer
});
