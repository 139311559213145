import React, { Component } from "react";
import { withStyles, Divider } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";

class ThanksContent extends Component {
  state = {};
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.root}>
        <div className={classes.ThanksCard}>
          <Hidden only={["sm", "md", "lg", "xl"]}>
            <img src="../assets/confirmation.svg" alt="Confirmed" />
          </Hidden>
          <Hidden only={["xs", "md", "lg", "xl"]}>
            <img src="../assets/confirmation.svg" alt="Confirmed" />
          </Hidden>
          <Hidden only={["xs", "sm"]}>
            <img src="../assets/confirmation.svg" alt="Confirmed" />
          </Hidden>

          <h1 className={classes.Congrats}>
            Congrats! You're on your way <br/>to the best swag in the game.
          </h1>
          <p className={classes.SitBack}>
            Sit back and relax, we're working on your mockups. We'll get those
            over to you within 24-36 hours.
          </p>
          <Divider className={classes.DividerLine} />
          <h4 className={classes.WorkTogether}>
            We can't wait to work together!
          </h4>
          <p className={classes.Credits}>The livingHR Market Team</p>
          <p className={classes.Credits}>market@livinghr.com</p>
        </div>
      </main>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    marginTop: "120px"
  },
  ThanksCard: {
    margin: "0 auto",
    width: "300px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      width: "1020px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "470px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "300px"
    }
  },
  Congrats: {
    fontFamily: "Cabin",
    fontSize: "48px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#4cb9e9",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    }
  },
  SitBack: {
    fontFamily: "PT Sans",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.5",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#000000",
    marginTop: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  DividerLine: {
    width: "90px",
    height: "4px",
    backgroundColor: "#000000",
    display: "inline-flex"
  },
  WorkTogether: {
    textTransform: "uppercase",
    fontFamily: "PT Sans",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "2",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#000000",
    marginBottom:0,
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px"
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: "12px"
    }
  },
  Credits: {
    fontFamily: "PT Sans",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.6",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#000000",
    margin: "0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  }
});
export default withStyles(styles)(ThanksContent);
