import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  ListItemIcon,
  ListItemText,
  Hidden,
  List,
  ListItem,
  Divider,
  Drawer,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import _ from "lodash";
import { selectedCategory, toggleCategoriesBar, clicked } from "../actions";

class CategoriesBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: 1,
      left: false,
      clicked: false
    };

    this.toggleActive = this.toggleActive.bind(this);
    window.MyselectedCategory = this.props.selectedCategory;
  }

  componentWillUpdate() {
    let text = "";
    _.map(this.props.cart.items, (item, index) => {
      let cat = "side" + item.category;
      text = document.getElementById(cat);
      if (text !== "" && text !== null) {
        // console.log(text);
        text.firstChild.style.color = "#434c5f";
        text.firstChild.style.fontWeight = "normal";
      }
    });
  }

  componentDidUpdate() {
    let text = "";
    _.map(this.props.cart.items, (item, index) => {
      let cat = "side" + item.category;
      text = document.getElementById(cat);
      if (text !== "" && text !== null) {
        // console.log(text);
        text.firstChild.style.color = "black";
        text.firstChild.style.fontWeight = "900";
      }
    });
  }

  toggleActive = (id, name) => {
    window.addEventListener("scroll", e => {
      return;
    });
    this.props.clicked(true);
    this.props.selectedCategory(id, name);
  };

  toggleDrawer = () => () => {
    this.props.toggleCategoriesBar();
  };

  itemsStatus = (item, classes) => {
    if (this.props.activeCategory === item.id) {
      // console.log(item.id, "=", this.props.activeCategory);
      return (
        <React.Fragment>
          <ListItemIcon className={classes.ItemIcon}>
            <img src={item.iconActive} alt={item.CatName} />
          </ListItemIcon>
          <ListItemText
            className={classes.ItemTextActive}
            primary={item.CatName}
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <ListItemIcon>
          <img src={item.icon} alt={item.CatName} />
        </ListItemIcon>
        <ListItemText
          className={classes.ItemText}
          primary={item.CatName}
          id={"side" + item.id}
        />
      </React.Fragment>
    );
  };

  render() {
    const { classes } = this.props;
    const sideList = (
      <div className={classes.list}>
        <List>
          {this.props.categories.map(
            (text, index) =>
              text.is_bonus === false && (
                <ListItem
                  button
                  key={index}
                  onClick={this.toggleActive.bind(this, text.id, text.CatName)}
                  className={classes.ListItem}
                  // disableTouchRipple={true}
                >
                  {this.itemsStatus(text, classes)}
                </ListItem>
              )
          )}
        </List>
      </div>
    );
    return (
      <div>
        <Drawer
          className={classes.drawer}
          open={this.props.open}
          onClose={this.toggleDrawer()}
        >
          <div tabIndex={0} role="button" className={classes.drawerPaper}>
            <div className={classes.drawerCatHeader}>
              <IconButton
                className={classes.button}
                aria-label="Close"
                onClick={this.toggleDrawer()}
              >
                <CloseIcon />
              </IconButton>
              <div className={classes.CategoriesTitle}>Categories</div>
            </div>
            <Divider />
            {sideList}
          </div>
        </Drawer>
        <Hidden only={["xs", "sm"]}>
          <div className={classes.SidebarMenu}>
            <div className={classes.CategoriesTitle}>
              <p>Categories</p>
            </div>

            <Divider />
            {sideList}
          </div>
        </Hidden>
      </div>
    );
  }
}
const drawerWidth = 300;
// const listStyle = (window.innerHeight < 700 ? {width: 190,
//     height: (window.innerHeight - 200),
//     overflow: "scroll",}: {width: 190,
//     height: "100%",
//     });console.log(listStyle);
const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerCatHeader: {
    display: "flex",
    alignItems: "center",
    padding: "20px 0 0 0",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    textTransform: "uppercase"
  },
  SidebarMenu: {
    // paddingLeft: "20px",
    position: "fixed",
    height: "100%",
    width:"150px"
  },
  CategoriesTitle: {
    paddingLeft: "20px",
    "& p": {
      textAlign: "center",
      textTransform: "uppercase",
      width: "80px",
      height: "18px",
      fontFamily: "PT Sans",
      fontSize: "12px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#000000"
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0",
      fontFamily: "PT Sans",
      fontSize: "12px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#000000"
    }
  },
  ListItem: {
    paddingLeft: "20px",
    "&:hover": {
      background: "none !important"
    }
  },
  ItemIcon: {
    marginRight: "0"
  },
  ItemText: {
    "& span": {
      // opacity: "0.54",
      fontFamily: "PT Sans",
      fontSize: "12px !important",
      // fontWeight: "normal !important",
      fontStyle: "normal !important",
      fontStretch: "norma !important",
      lineHeight: "normal !important",
      letterSpacing: "normal !important",
      color: "#79797a"
    },
    padding: "0 !important"
  },

  ItemTextActive: {
    "& span": {
      fontFamily: "PT Sans !important",
      fontSize: "12px !important",
      // fontWeight: "normal",
      fontStyle: "normal !important",
      fontStretch: "norma !important",
      lineHeight: "normal !important",
      letterSpacing: "normal !important",
      color: "#4cb9e9 !important"
    }
  },
  list: {
      position: "fixed",
      height: "80%",
      overflowY: "auto"
  },
  fullList: {
    width: "auto"
  }
});

const mapStateToProps = state => {
  return {
    selectedCategory: state.category.selectedCategory,
    toggleCategoriesBar: state.category.toggleCategoriesBar,
    open: state.category.categoriesBarOpen,
    activeCategory: state.category.selectedCategory,
    cart: state.cart
  };
};

export default connect(
  mapStateToProps,
  { selectedCategory, toggleCategoriesBar, clicked }
)(withStyles(styles, { withTheme: true })(CategoriesBar));
