const OrderReducer = (state = {}, action) => {
  switch (action.type) {
    case "SUBMIT_ORDER":
      console.log("Submit order", action.payload);

      return Object.assign({}, state, {
        order: action.payload
      });

    default:
      return state;
  }
};

export default OrderReducer;
