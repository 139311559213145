const CategoryReducer = (
  state = {
    selectedCategory: 1,
    categoryName: "Water Bottles",
    categoriesBarOpen: false,
    clicked: false
  },
  action
) => {
  switch (action.type) {
    case "CATEGORY_SELECTED":
      // console.log("CATEGORY_SELECTED: ", action.payload.name);
      return {
        ...state,
        selectedCategory: action.payload.id,
        categoryName: action.payload.name
      };
    case "TOGGLE_CATEGORIES_BAR":
      return {
        ...state,
        categoriesBarOpen: !state.categoriesBarOpen
      };
    case "CLICKED":
      return {
        ...state,
        clicked: action.payload
      };
    default:
      return state;
  }
};

export default CategoryReducer;
