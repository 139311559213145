import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { deleteFromCart } from "../actions";

class CartElement extends Component {
  state = {};
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <li className={classes.CartElement}>
          <div className={classes.ItemImg}>
            <img src={this.props.item.img} alt="Cart Element" />
          </div>
          <div className={classes.ItemDesc}>
            <h3 className={classes.ItemTitle}>{this.props.item.name}</h3>
            <p className={classes.ItemPrice}>
              Starting at ${this.props.item.price}
            </p>
          </div>
          <div className={classes.ItemActions}>
            <IconButton
              aria-label="Delete"
              onClick={() => {
                this.props.deleteFromCart(this.props.item);
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </div>
        </li>
      </React.Fragment>
    );
  }
}
const styles = theme => ({
  CartElement: {
    listStyle: "none",
    padding: "10px 15px 10px 15px",
    display: "flex",
    borderBottom: "1px solid #d4d9e2"
  },
  ItemImg: {
    width: "42px",
    height: "42px",
    borderRadius: "2px",
    border: "1px solid #d4d9e2",

    "& img": {
      width: "40px",
      height: "40px",
      objectFit: "contain"
    }
  },
  ItemDesc: {
    paddingLeft: "15px"
  },
  ItemTitle: {
    width: "180px",
    minHeight: "14px",
    marginTop: "5px",
    marginBottom: "0px",
    fontFamily: "PT Sans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000000",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  ItemPrice: {
    width: "110px",
    height: "11px",
    margin: "0",
    opacity: "0.55",
    fontFamily: "PT Sans",
    fontSize: "11px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#79797a"
  },
  ItemActions: {
    width: "100%",
    textAlign: "right"
  }
});

const mapStateTopProps = state => {
  return {
    deleteFromCart: state.deleteFromCart
  };
};

export default connect(
  mapStateTopProps,
  { deleteFromCart }
)(withStyles(styles, { withTheme: true })(CartElement));
