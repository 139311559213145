import React, { Component } from "react";
import Dropzone from "react-dropzone";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Form, Field, reduxForm } from "redux-form";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import _ from "lodash";

import submit from "./FormSubmit";

import {
  required,
  email,
  date,
  length,
  numericality,
  format
} from "redux-form-validators";

import {
  Grid,
  FormControl,
  Input,
  Hidden,
  // Button,
  // Fab,
  FormHelperText
} from "@material-ui/core";
import { css } from "@emotion/core";
import { GridLoader } from "react-spinners";

//Components
import Breadcrumb from "./Breadcrumb";
import Cart from "./Cart";
import RemoteSubmitButton from "./RemoteSubmitButton";

const override = css`
  color: #4cb9e9;
`;
class FormContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: false,
      file2: null,
      fileError: false
    };
    this.renderDropZone1 = this.renderDropZone1.bind(this);
    this.renderDropZone2 = this.renderDropZone2.bind(this);
  }
  componentDidUpdate() {
    // console.log("From:  ", this.props);
    // if (this.props.form.createPO.primaryLogo !== "") {
    //   this.handleImg(this.props.form.createPO.primaryLogo);
    // }
  }
  componentWillMount() {
    this.props.initialize({ budget: this.roundUpBudget() });
  }
  componentDidMount() {
    document.getElementById("upload2").style.display = "none";
    document.getElementById("uploadS2").style.display = "none";
  }
  renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <FormHelperText id="component-error-text" style={{ color: "#f44336" }}>
          {error}
        </FormHelperText>
      );
    }
  };

  colorInput = () => {};
  renderTextInput = ({ input, label, placeholder, classes, meta }) => {
    return (
      <FormControl fullWidth className="">
        {/* <InputLabel className={classes.PlaceholderLabel}>{label}</InputLabel> */}
        <Input
          {...input}
          error={meta.touched && meta.error && true}
          placeholder={placeholder}
        />
        {this.renderError(meta)}
      </FormControl>
    );
  };

  renderDropZone1({ input, label, classes, meta }) {
    return (
      <React.Fragment>
        <Dropzone
          onDrop={(files, rejectedFiles) => {
            input.onChange(files);
            // console.log(files[0]);
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            let logo1 = document.getElementById("logo1");
            let upload1 = document.getElementById("upload1");
            let upload2 = document.getElementById("upload2");
            let drop = document.getElementById("drop");
            reader.onloadend = () => {
              var fileType = reader.result
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];
              // console.log(fileType);
              if (
                fileType === "application/pdf" ||
                fileType === "application/postscript"
              ) {
                logo1.src = "../assets/no-img.png";
              } else {
                logo1.src = reader.result;
              }
              upload1.style.display = "none";
              upload2.style.display = "block";
              drop.style.border = "none";
            };
          }}
          className={
            meta.submitFailed == false
              ? classes.DropArea
              : classes.DropArea
          }
          id="drop"
        >
          <div>
            <div id="upload1" className={classes.DropAreatext}>
              <h3> {label} </h3>
              <div className={classes.IconUpload} />
              <p>
                Drag and drop to upload{" "}
                <small>
                  or <a href="#">choose file</a>
                </small>{" "}
              </p>
            </div>
            <div id="upload2" className={classes.DropAreatext}>
              <h3> Upload Complete </h3>
              <div className={classes.Thumbnail}>
                <img
                  id="logo1"
                  style={{
                    width: "100%"
                  }}
                  src={this.state.file}
                />
              </div>
              <a className={classes.UploadLink} href="#">
                Upload another
              </a>
            </div>
          </div>
        </Dropzone>
        {this.renderError(meta)}
      </React.Fragment>
    );
  }

  renderDropZone2({ input, label, classes }) {
    return (
      <Dropzone
        onDrop={(files, rejectedFiles) => {
          input.onChange(files);
          // console.log(files[0]);
          const reader = new FileReader();
          reader.readAsDataURL(files[0]);
          let logo2 = document.getElementById("logo2");
          let uploadS1 = document.getElementById("uploadS1");
          let uploadS2 = document.getElementById("uploadS2");
          reader.onloadend = () => {
            var fileType2 = reader.result
              .split(",")[0]
              .split(":")[1]
              .split(";")[0];
            // console.log(fileType2);
            if (
              fileType2 === "application/pdf" ||
              fileType2 === "application/postscript"
            ) {
              logo2.src = "../assets/no-img.png";
            } else {
              logo2.src = reader.result;
            }
            uploadS1.style.display = "none";
            uploadS2.style.display = "block";
          };
        }}
        className={classes.DropArea}
      >
        <div>
          <div id="uploadS1" className={classes.DropAreatext}>
            <h3> {label} </h3>
            <div className={classes.IconUpload} />
            <p>
              Drag and drop to upload{" "}
              <small>
                or <a href="#">choose file</a>
              </small>{" "}
            </p>
          </div>
          <div id="uploadS2" className={classes.DropAreatext}>
            <h3> Upload Complete </h3>
            <div className={classes.Thumbnail}>
              <img
                id="logo2"
                style={{
                  width: "100%"
                }}
                src={this.state.file}
              />
            </div>
            <a className={classes.UploadLink} href="#">
              Upload another
            </a>
          </div>
        </div>
      </Dropzone>
    );
  }

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value
    });
  };

  normalizeDate = (val, prevVal) => {
    // Prevent non-digit characters being entered
    if (isNaN(parseInt(val[val.length - 1], 10))) {
      return val.slice(0, -1);
    }

    // When user is deleting, this prevents immediate re-addition of '/' when it's deleted
    if (prevVal && prevVal.length >= val.length) {
      return val;
    }

    // Add / at appropriate sections of the input
    if (val.length === 2 || val.length === 5) {
      val += "/";
    }

    // Prevent characters being entered after Dob is full
    if (val.length >= 10) {
      return val.slice(0, 10);
    }

    return val;
  };

  roundUpBudget = () => {
    let val = 0;
    _.map(this.props.cart.items, (item, index) => {
      val += parseInt(item.price);
      // console.log(val);
    });
    return Math.ceil(val / 10) * 10;
  };

  render() {
    const { classes } = this.props;
    // console.log("Props: ", this.props);

    return (
      <div>
        <Grid
          container
          justify="center"
          spacing={24}
          className={classes.MainContainer}
        >
          <Grid item className={classes.Center}>
            <Grid container>
              <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>
                <Hidden only={["xs", "sm"]}>
                  <div className={classes.FormTopContainer}>
                    <Breadcrumb />
                    <h1 className={classes.BlockTitle}>
                      {" "}
                      <Link to="/step2">
                        <IconButton
                          aria-label="Back"
                          className={classes.BackButton}
                        >
                          <ChevronLeft />
                        </IconButton>
                      </Link>
                      Swag Pack Wrap - Up Questions{" "}
                    </h1>
                  </div>
                </Hidden>

                <div className={classes.FormContainer}>
                  <Form onSubmit={this.props.handleSubmit}>
                    <h1 className={classes.SectionHeading}>
                      Order information{" "}
                      <small className={classes.Required}>
                        <sup>*</sup>Required
                      </small>
                    </h1>
                    <p className={classes.label}>
                      How many swag packs are you looking to get ?<sup> * </sup>
                    </p>
                    <Field
                      name="amount"
                      component={this.renderTextInput}
                      classes={classes}
                      placeholder="Minimum of 50"
                    />
                    <Grid id="bottom-row" container spacing={24}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <p className={classes.label}>
                          What 's your budget per swag pack/person?{" "}
                        </p>
                        <Field
                          name="budget"
                          component={this.renderTextInput}
                          classes={classes}
                          placeholder="Enter budget"
                          // normalize={this.normalizeBudget}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <p className={classes.label}>
                          Do you need these by a certain date?{" "}
                        </p>
                        <Field
                          name="date"
                          component={this.renderTextInput}
                          classes={classes}
                          placeholder="MM / DD / YYYY"
                          normalize={this.normalizeDate}
                        />
                      </Grid>
                    </Grid>
                    <p className={classes.label}>
                      Any items we missed or comments you 'd like to add?{" "}
                    </p>
                    <Field
                      name="comments"
                      component={this.renderTextInput}
                      classes={classes}
                      placeholder="From Beats headphones to Shinola watches, we can get it
                      done!"
                    />
                    <p
                      className={classes.label}
                      style={{
                        width: "100%"
                      }}
                    >
                      Please upload the logo you 'd like to put on your swag
                    </p>
                    <small className={classes.SmallText}>
                      File formats : .pdf, .png, .ai, .eps, .jpeg
                    </small>

                    <Grid container spacing={24} style={{ paddingTop: "20px" }}>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Field
                          name="primaryLogo"
                          component={this.renderDropZone1}
                          classes={classes}
                          label="Primary Logo"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Field
                          name="secondaryLogo"
                          component={this.renderDropZone2}
                          classes={classes}
                          label="Secondary Logo"
                        />
                      </Grid>
                    </Grid>
                    <h1 className={classes.SectionHeading}>
                      Contact information{" "}
                      <small className={classes.Required}>
                        <sup>*</sup>Required
                      </small>
                    </h1>
                    <p className={classes.label}>
                      Your full name<sup>*</sup>
                    </p>
                    <Field
                      name="name"
                      component={this.renderTextInput}
                      classes={classes}
                      placeholder="Jane Doe"
                    />
                    <Grid container spacing={24}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <p className={classes.label}>
                          Work email address?<sup>*</sup>
                        </p>
                        <Field
                          name="email"
                          component={this.renderTextInput}
                          classes={classes}
                          placeholder="example@company.com"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <p className={classes.label}>Contact number </p>
                        <Field
                          name="number"
                          component={this.renderTextInput}
                          classes={classes}
                          placeholder="So we can contact you about your package"
                        />
                      </Grid>
                    </Grid>
                    <p className={classes.label}>
                      What's the name of your company?<sup>*</sup>
                    </p>
                    <Field
                      name="company"
                      component={this.renderTextInput}
                      classes={classes}
                      placeholder="Enter name"
                    />
                    <Hidden only={["md", "lg", "xl"]}>
                      <div className={classes.ScreenAction}>
                        {/* <Fab
                      variant="extended"
                      type="submit"
                      aria-label="Next"
                      className={classes.FormButton}
                      color="secondary"
                      // component={Link}
                      // to="/step3"
                    >
                      Submit Pack
                    </Fab> */}
                        <RemoteSubmitButton />
                      </div>
                    </Hidden>
                  </Form>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <Cart step="/step4" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.props.submitting && (
          <div className={classes.Loading}>
            <GridLoader
              css={override}
              sizeUnit={"px"}
              size={50}
              color={"#123abc"}
              loading={true}
            />
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  MainContainer: {
    paddingTop: "120px",
    paddingBottom: "100px"
  },
  Center: {
    [theme.breakpoints.up("xl")]: {
      minWidth: "1440px",
      paddingLeft: "0 !important"
    }
  },
  BlockTitle: {
  width: '654px',
  height: '31px',
  fontFamily: "Cabin",
  fontSize: "24px",
  fontWeight: "normal",
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: "#4cb9e9",
  },
  FormTopContainer: {
    padding: "10px 30px",
    borderBottom: "1px solid #d4d9e2",
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 0
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0
    }
  },
  FormContainer: {
    paddingLeft: "30px",
    maxWidth: "894px",
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 0
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px 35px 0 35px;"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "30px 20px 0 33px"
    }
  },
  SectionHeading: {
    width: "100%",
    height: "24px",
    fontFamily: "Cabin",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000000"
  },
  BackButton: {
    color: "#4cb9e9",
    marginTop: "-3px"
  },
  Required: {
    width: "53px",
    height: "12px",
    fontFamily: "PT Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#79797a"
  },
  label: {
    width: "100%",
    height: "25px",
    paddingTop: "15px",
    fontFamily: "Cabin",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "30px"
    }
  },
  PlaceholderLabel: {
    width: "100%",
    height: "21px",
    opacity: "0.54",
    fontFamily: "PT Sans",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#79797a"
  },
  SmallText: {
    width: "237px",
    height: "14px",
    fontFamily: "PT Sans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#79797a"
  },
  DropArea: {
    width: "435px",
    height: "231px",
    paddingTop: "10px",
    borderRadius: "4px",
    backgroundColor: "#fafafa",
    [theme.breakpoints.down("sm")]: {
      width: "344px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px"
    }
  },
  DropAreaError: {
    width: "435px",
    height: "231px",
    paddingTop: "10px",
    borderRadius: "4px",
    backgroundColor: "#fafafa",
    border: "1px solid #f44336",
    [theme.breakpoints.down("sm")]: {
      width: "344px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px"
    }
  },
  DropAreatext: {
    width: "190px",
    margin: "0 auto",
    textAlign: "center",
    "& h3": {
      textTransform: "uppercase",
      height: "19px",
      marginBottom: "5px",
      fontFamily: "PT Sans",
      fontSize: "12px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "1.58",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#000000"
    },
    "& p": {
      width: "190px",
      height: "38px",
      fontFamily: "PT Sans",
      fontSize: "14px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "1.36",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#000000",
      "& small": {
        fontFamily: "PT Sans",
        fontWeight: "normal",
        "& a": {
          fontFamily: "FuturaLTPro",
          fontWeight: "normal",
          color: "#4cb9e9"
        }
      }
    }
  },
  IconUpload: {
    width: "80px",
    height: "80px",
    objectFit: "contain",
    backgroundImage: "url(../assets/icons/cloud-upload.svg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    alignContent: "center",
    margin: "0 auto"
  },
  ScreenAction: {
    width: "100%",
    bottom: 10,
    textAlign: "center",
    paddingTop: "30px"
  },
  FormButton: {
    width: "328px",
    height: "56px",
    borderRadius: "4px",
    backgroundColor: "#3577d4",
    fontFamily: "Futura",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.75",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  },
  Thumbnail: {
    width: "80px",
    height: "80px",
    margin: "15px auto",
    backgroundColor: "#ffffff",
    border: "1px solid #d4d9e2",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  UploadLink: {
    fontFamily: "PT Sans",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.36",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#4cb9e9",
    textDecoration: "none"
  },
  Loading: {
    position: "fixed",
    // display: "none",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: "2",
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  ErrorText: {
    color: "#f44336"
  }
});

const validations = {
  amount: [
    required({ msg: "Required" }),
    numericality({
      int: true,
      ">=": 50,
      msg: { greaterThanOrEqualTo: "You must be at least 50 swag packs" }
    })
  ],
  budget: [
    required({ msg: "Required" }),
    numericality({
      int: true
    })
  ],

  email: [
    required({ msg: "Required" }),
    email(),
    format({
      width: /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(gmail|aol|hotmail|yahoo|outlook|icloud|inbox|mail)\.com$/i,
      message: { defaultMessage: "Must use corporate email address" }
    })
  ],
  date: [
    date({
      format: "mm/dd/yyyy",
      ">": "today",
      allowBlank: true
    })
  ],
  // primaryLogo: [required({ msg: "Required" })],
  name: [
    required({ msg: "Required" }),
    length({ min: 3 }),
    format({
      with: /^[a-zA-Z\s]*$/i,
      message: { defaultMessage: "Letters only" }
    })
  ],
  company: [required({ msg: "Required" })]
};

// Reusable with any other form
const validate = values => {
  const errors = {};
  for (let field in validations) {
    let value = values[field];
    errors[field] = validations[field]
      .map(validateField => {
        return validateField(value, values);
      })
      .find(x => x);
  }
  let regex = new RegExp(
    "^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(gmail|aol|hotmail|yahoo|outlook|icloud|inbox|mail).com$"
  );
  if (regex.test(values.email)) {
    // console.log("input email", values.email);
    errors.email = "Must use corporate email address";
  }
  return errors;
};

const submitForm = reduxForm({
  form: "createPO",
  validate,
  onSubmit: submit
})(withStyles(styles, { withTheme: true })(FormContent));

const mapStateToProps = state => {
  // console.log("After submit  ", state);
  return {
    cart: state.cart
  };
};
export default connect(mapStateToProps)(submitForm);
